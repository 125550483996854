
const KTAppOptions = {
    "colors": {
        "state": {
            "brand": "#5d78ff",
            "dark": "#282a3c",
            "light": "#ffffff",
            "primary": "#5867dd",
            "success": "#34bfa3",
            "info": "#36a3f7",
            "warning": "#ffb822",
            "danger": "#fd3995"
        },
        "base": {
            "label": ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"],
            "shape": ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"]
        }
    }
};


const appConfig = new function () {
    const self = this;

    self.initDefaults = function () {
        "use strict";

        $(window).resize(function () {
            self.adjustContainerHeight();	
        });

        // apply tooltip
        jQuery('[data-toggle="tooltip"]').tooltip();
        jQuery('[data-toggle="popover"]').popover();

    };

    self.adjustContainerHeight = function () {
        $(".kt-grid--root ").css("min-height", $(window).innerHeight());
    };

    self.initGridViewFilter = function () {

        $("#openFilter").click(function () {
            $(".filter-box-wrapper").css({ "opacity": 1, "right": "4.5%" });
        });

        $("#closeFilter").click(function () {
            $(".filter-box-wrapper").css({ "opacity": 1, "right": "-100%" });
        });

        $('.customSelect > label').on('click', function (e) {
            $('.customSelect > label').removeClass('active');
            $(this).addClass('active');
            var targetId = this.id.replace('x', '');

            $('.selectContainer').hide();
            $('#' + targetId).css('display', 'flex');
        });
    }

    self.initKTDefaults = function () {
        KTApp.init(KTAppOptions);
        KTLayout.init();
    };
};

function toggleFullScreen() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    }
}
